export default {
  namespaced: true,
  state: {
    shallContentShowOverlay: false,
    showUpdate: false,
  },
  getters: {},
  mutations: {
    TOGGLE_CONTENT_OVERLAY(state, value) {
      state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay
    },
    TOGGLE_UPDATE(state, value) {
      state.showUpdate = value !== undefined ? value : !state.showUpdate
    },
  },
  actions: {
    toggleUpdate({ commit }, value) {
      console.log('🚀 ~ toggleUpdate ~ value:', value)
      commit('TOGGLE_UPDATE', value)
      localStorage.removeItem('videoData')
      localStorage.removeItem('logData')
      window.location.reload(true)
    },
  },
}
