import ability from './ability'

export const canNavigate = to => to.matched.some(route => ability.can(route.meta.action || 'read', route.meta.resource))

// export const isAllowed = (to, ids = []) => (to.meta.id ? ids.includes(to.meta.id) : true)
export const isAllowed = (to, ids = []) => {
  if (to.meta.id) return ids.includes(to.meta.id)
  if (to.meta.allow) return to.meta.allow

  return true
}

export const _ = undefined
