/* eslint-disable import/no-cycle */
import { getI18nLanguage } from '@/plugins/i18n'
import { apiHttp } from '../axiosApi'
import {
  facilityPath,
  facilityActive,
  facilitylocation,
} from '../config/apiRoute'

export const getFacilitiesActive = code => apiHttp('GET', `${facilityActive}`, null, {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Basic ${btoa(`${code}`)}`,
    'Accept-Language': getI18nLanguage(),
  },
})

export const getFacilitiesLocation = (lat, lon) => apiHttp('GET', `${facilitylocation}${lat}/${lon}`, null, {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Basic ${btoa(`${lat},${lon}`)}`,
    'Accept-Language': getI18nLanguage(),
  },
})

export const getFacilities = () => apiHttp('GET', `${facilityPath}`)
