<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <!-- <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          color="primary"
          class="v-avatar-light-bg primary--text"
          v-on="on"
        >
          <v-img
            v-if="userData.avatar"
            :src="userData.avatar"
          ></v-img>
          <v-icon
            v-else
            color="primary"
            size="28"
          >
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-avatar>
      </v-badge> -->

      <v-icon
        color="white"
        size="30"
        v-bind="attrs"
        v-on="on"
      >
        {{ icons.mdiDotsVertical }}
      </v-icon>
    </template>
    <v-list>
      <div
        v-if="isPrivate"
        class="pb-3 pt-2"
      >
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar
            size="40px"
            color="primary"
            class="v-avatar-light-bg primary--text"
          >
            <v-img
              v-if="userData && userData.avatar"
              :src="userData.avatar"
            ></v-img>
            <v-icon
              v-else
              color="primary"
              size="28"
            >
              {{ icons.mdiAccountOutline }}
            </v-icon>
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{ userData.fullName || userData.username }}
          </span>
          <small class="text--disabled text-capitalize">{{ userData.user_role }}</small>
          <!-- <small class="text--disabled text-capitalize">{{ resolveUserRoleText(userData.role) }}</small> -->
        </div>
      </div>

      <v-divider
        v-if="isPrivate"
        class="mb-2"
      ></v-divider>

      <!-- Profile -->
      <!-- <v-list-item :to="{ name: 'apps-user-view', params: { id: 21 } }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Profile</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <!-- Email -->
      <!-- <v-list-item :to="{ name: 'apps-email' }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiEmailOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Inbox</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <!-- Chat -->
      <!-- <v-list-item :to="{ name: 'apps-chat' }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiChatOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Chat</v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-badge
            inline
            color="error"
            content="2"
          >
          </v-badge>
        </v-list-item-action>
      </v-list-item> -->

      <!-- <v-divider class="my-2"></v-divider> -->

      <!-- Settings -->
      <!-- <v-list-item :to="{ name: 'page-account-settings' }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiCogOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <!-- Pricing -->
      <!-- <v-list-item :to="{ name: 'page-pricing' }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiCurrencyUsd }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Pricing</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <!-- FAQ -->
      <!-- <v-list-item :to="{ name: 'page-faq' }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiHelpCircleOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>FAQ</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <!-- <v-divider class="my-2"></v-divider> -->

      <div v-if="isPrivate">
        <!-- Reset Password -->
        <v-list-item :to="{ name: 'auth-reset-password' }">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiLockReset }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ t('settings.change_password') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="my-2"></v-divider>

        <!-- Logout -->
        <v-list-item @click="logoutUser">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiLogoutVariant }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ t('settings.logout') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="my-2"></v-divider>
      </div>

      <div>
        <!-- report -->
        <v-list-item @click="$emit('report', true)">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiTextBoxEditOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ t('settings.report') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiLockReset,
  mdiDotsVertical,
  mdiTextBoxEditOutline,
} from '@mdi/js'

// import { useRouter } from '@core/utils'
import { initialAbility } from '@/plugins/acl/config'
import { getCurrentInstance } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import useCryptoJs from '@/@core/utils/useCryptoJs'
import store from '@/store'

export default {
  props: {
    isPrivate: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    // const { router } = useRouter()
    const { t } = useUtils()
    const vm = getCurrentInstance().proxy
    const { userData } = useCryptoJs()

    const logoutUser = () => {
      // Reset ability
      vm.$ability.update(initialAbility)
      store.dispatch('auth/logoutAction')

      // Redirect to login page
      // router.push({ name: 'public', params: { code } })
    }

    const resolveUserRoleText = role => {
      if (role === 'subscriber' || role === 'F') return 'Facility'
      if (role === 'author' || role === 'R') return 'Representative'
      if (role === 'maintainer') return 'Maintainer'
      if (role === 'editor') return 'Editor'
      if (role === 'admin' || role === 'A') return 'Administrator'

      return 'Administrator'
    }

    return {
      userData,
      logoutUser,
      resolveUserRoleText,
      t,

      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiLockReset,
        mdiDotsVertical,
        mdiTextBoxEditOutline,
      },
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
