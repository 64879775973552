export default {
  Billing_to: 'Bill To',
  Dashboards: 'Dashboards',
  Dashboard: 'Dashboard',
  CRM: 'CRM',
  Analytics: 'Analytics',
  eCommerce: 'eCommerce',
  Calendar: 'Calendar',
  Chat: 'Chat',
  chat_support: 'Chat Support',
  Email: 'Email',
  Invoice: 'Invoice',
  Invoice_to: 'Invoice To',
  Receipt: 'Receipt',
  List: 'List',
  Preview: 'Preview',
  Edit: 'Edit',
  Add: 'Add',
  User: 'User',
  'User List': 'User List',
  'User View': 'User View',
  'APPS AND PAGES': 'APPS AND PAGES',
  Pages: 'Pages',
  Authentication: 'Authentication',
  'Login V1': 'Login V1',
  'Login V2': 'Login V2',
  'Register V1': 'Register V1',
  'Register V2': 'Register V2',
  'Forgot Password V1': 'Forgot Password V1',
  'Forgot Password V2': 'Forgot Password V2',
  'Reset Password V1': 'Reset Password V1',
  'Reset Password V2': 'Reset Password V2',
  Miscellaneous: 'Miscellaneous',
  'Under Maintenance': 'Under Maintenance',
  Error: 'Error',
  'Coming Soon': 'Coming Soon',
  'Not Authorized': 'Not Authorized',
  'Knowledge Base': 'Knowledge Base',
  'Account Settings': 'Account Settings',
  Pricing: 'Pricing',
  FAQ: 'FAQ',
  'USER INTERFACE': 'USER INTERFACE',
  Typography: 'Typography',
  Icons: 'Icons',
  Gamification: 'Gamification',
  Cards: 'Cards',
  Basic: 'Basic',
  Statistics: 'Statistics',
  Advance: 'Advance',
  Actions: 'Actions',
  Action: 'Action',
  Chart: 'Chart',
  Components: 'Components',
  Alert: 'Alert',
  Avatar: 'Avatar',
  Badge: 'Badge',
  Button: 'Button',
  Menu: 'Menu',
  'Expansion Panel': 'Expansion Panel',
  Dialog: 'Dialog',
  Pagination: 'Pagination',
  Tabs: 'Tabs',
  Tooltip: 'Tooltip',
  Chip: 'Chip',
  Snackbar: 'Snackbar',
  Stepper: 'Stepper',
  Timeline: 'Timeline',
  Treeview: 'Treeview',
  'FORMS & TABLES': 'FORMS & TABLES',
  'Forms Elements': 'Forms Elements',
  Autocomplete: 'Autocomplete',
  Checkbox: 'Checkbox',
  Combobox: 'Combobox',
  'File Input': 'File Input',
  Radio: 'Radio',
  'Range Slider': 'Range Slider',
  Select: 'Select',
  Slider: 'Slider',
  Switch: 'Switch',
  Textarea: 'Textarea',
  Textfield: 'Textfield',
  'Date Picker': 'Date Picker',
  'Month Picker': 'Month Picker',
  'Time Picker': 'Time Picker',
  Rating: 'Rating',
  'Form Layout': 'Form Layout',
  'Form validation': 'Form validation',
  Tables: 'Tables',
  'Simple Table': 'Simple Table',
  Datatable: 'Datatable',
  'CHARTS AND MAPS': 'CHARTS AND MAPS',
  Charts: 'Charts',
  'Apex Chart': 'Apex Chart',
  Chartjs: 'Chartjs',
  'Leaflet Maps': 'Leaflet Maps',
  Others: 'Others',
  'Menu Levels': 'Menu Levels',
  'Menu Level 2.1': 'Menu Level 2.1',
  'Menu Level 2.2': 'Menu Level 2.2',
  'Menu Level 3.1': 'Menu Level 3.1',
  'Menu Level 3.2': 'Menu Level 3.2',
  'Disabled Menu': 'Disabled Menu',
  'Raise Support': 'Raise Support',
  Documentation: 'Documentation',
  'Charts & Maps': 'Charts & Maps',
  Apps: 'Apps',
  'User Interface': 'User Interface',
  'Forms & Tables': 'Forms & Tables',
  'Buttom Options': 'Buttom Options',
  VIEWS: 'VIEWS',
  Users: 'Users',
  Transactions: 'Transactions',
  'Access Control': 'Access Control',
  'Payment Methods': 'Payment Methods',
  'Order Videos': 'Order Videos',
  'Order History': 'Order History',
  'Payment History': 'Payment History',
  Configurations: 'Configurations',
  Sports: 'Sports',
  Fields: 'Fields',
  'Field Monitoring': 'Field Monitoring',
  Facilities: 'Facilities',
  Cameras: 'Cameras',
  'App Users': 'App Users',
  'Console Users': 'Console Users',
  'User Activity': 'User Activity',
  Highlights: 'Highlights',
  Groups: 'Groups',
  Roles: 'Roles',
  Notifications: 'Notifications',
  'Console Notifications': 'Console Notificaciones',
  'App Notifications': 'App Notifications',
  'Recurring Orders': 'Recurring Orders',
  Videos: 'Videos',
  Payments: 'Payments',
  Loading: 'Loading',
  search: 'Search',
  actions: 'Actions',
  locale: 'en-US',
  filter_by: 'filter by',
  minutes: {
    min_2: '2 Minutes',
    min_5: '5 Minutes',
    min_60: '60 Minutes',
    min_90: '90 Minutes',
    min_120: '120 Minutes',
    min_150: '150 Minutes',
  },
  required: {
    null: ' is required',
    'length 0': ' must be less than 0 characters',
    'length 3': ' must be less than 3 characters',
    'length 4': ' must be less than 4 characters',
    'length 5': ' must be less than 5 characters',
    'length 10': ' must be less than 10 characters',
    'length 15': ' must be less than 15 characters',
    'length 20': ' must be less than 20 characters',
    'length 25': ' must be less than 25 characters',
    'length 40': ' must be less than 40 characters',
    'length 50': ' must be less than 50 characters',
    'length 100': ' must be less than 100 characters',
    'length 200': ' must be less than 200 characters',
    'length 250': ' must be less than 250 characters',
    length: ' must be less than or equal to ',
    email: 'E-mail must be valid',
    nullGeneral: 'This field is required',
    invalidFormat: 'Invalid Format',
    label: '(*) Required',
    labelShort: '(*) Req.',
    needData: 'Missing data to add, please complete them',
  },
  tooltip: {
    new: 'New',
    save: 'Save',
    edit: 'Edit',
    delete: 'Delete',
    show: 'Detail',
    cancel: 'Cancel',
    next_tab: 'Next Tab',
    back: 'Back',
    list: 'List',
    back_table: 'Back to List',
    info: 'Information',
    search: 'Search',
    actions: 'Actions',
    refresh: 'Refresh',
    left: 'Left',
    right: 'Right',
    order: 'Order',
    no_data: 'There are no videos to show',
    enable: 'Enable',
    disable: 'Disable',
    submit: 'Submit',
    default: 'Default',
    copied: 'Copied',
    not_copy: 'Can not copy',
    view_detail: 'View Detail',
    view_video: 'View Video',
    confirm_order: 'Confirm Order',
    edit_order: 'Edit Order',
    close: 'Close',
    download: 'Download',
    print: 'Print',
    print_selection: 'Print Selection',
    generating_pdf: 'Generating PDF',
    continue: 'Continue',
    print_receivet: 'Print receipt',
    generate_report: 'Generate Report',
    play_video: 'Play video',
    today: 'Today',
    prev: 'Prev',
    next: 'Next',
    export: 'Export',
    filters: 'Filters',
    accept: 'Accept',
    send: 'Send',
    resend: 'Resend',
    share: 'Share',
    at_minute: 'at minute',
    enabled: 'Enabled',
    disabled: 'Disabled',
    expired: 'Expired',
  },
  weekdays: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
  },
  time: {
    weeks: 'Weeks',
    week: 'Week',
    months: 'Months',
    month: 'Month',
    years: 'Years',
    year: 'Year',
  },
  select: {
    role: 'Select Role',
    facility: 'Select Facility',
    status: 'Select Status',
    sport: 'Select Sport',
    field: 'Select Field',
    chunk: 'Select Chunk',
    payment_method: 'Select Payment Method',
    group: 'Select Group',
    action: 'Select Action',
    date_range: 'Select Date Range',
  },
  status: {
    status: 'Status',
    active: 'Active',
    inactive: 'Inactive',
    pending: 'Pending',
    paid: 'Paid',
    free: 'Free',
    processing: 'Processing',
    scheduled: 'Scheduled',
    cancelled: 'Cancelled',
    successful: 'Successful',
    declined: 'Declined',
    refunded: 'Refunded',
    partial_refund: 'Partial Refund',
    recording: 'Recording',
  },
  users: {
    users: 'Users',
    user: 'User',
    dni: 'DNI',
    type: 'User Type',
    create: 'Create User',
    edit: 'Edit User',
    show: 'Show User',
    list: 'Users List',
    username: 'Username',
    name: 'Name',
    id: 'ID User',
    lastname: 'Lastname',
    fullname: 'Fullname',
    email: 'Email',
    phone: 'Phone',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
    other: 'Other',
    bio: 'Bio',
    birthday: 'Birthday',
    address: 'Address',
    password: 'Password',
    password_conf: 'Confirm password',
    password_not_match: 'Passwords do not match',
    password_restore: 'Restore password',
    save_password: 'Save password',
    account: 'Account',
    security: 'Security',
    info: 'Info',
    overview: 'Overview',
    new_password: 'New Password',
    confirm_new_password: 'Confirm New Password',
    password_hint: "Make sure it's between 6 and 20 characters.",
    contact: 'Contact',
    users_app: 'Users App',
    country: 'Country',
    register_date: 'Register Date',
    register_hour: 'Register Hour',
    last_login: 'Last Login Date',
    last_login_hour: 'Last Login Hour',
    website: 'Website',
    user_activity: 'User Activity',
    experience: 'Experience',
    age: 'Age',
    consent: 'Consent',
  },
  sports: {
    sports: 'Sports',
    sport: 'Sport',
    id: 'ID Sport',
    name: 'Name',
    time: 'Playing Time',
    description: 'Description',
  },
  transactions: {
    id: 'Transaction ID',
    transactions: 'Transactions',
    transaction: 'Transaction',
    paid: 'Paid',
    client: 'Client',
    video_date: 'Video Date',
    field: 'Field',
    start_time: 'Start Time',
    end_time: 'End Time',
    video_time: 'Video Time',
    amount: 'Amount',
    add_payment: 'Add Payment',
    pay: 'Pay',
    continue_to_paymen: 'Continue to payment',
    get_your_video: 'Get your video',
    date: 'Date',
    payment_date: 'Payment Date',
    payment_amount: 'Payment Amount',
    payment_method: 'Payment Method',
    note: 'Note',
    video_info: 'Video Info',
    user_name: 'User/Name',
    transaction_date: 'Transaction Date',
    order_date: 'Order Date',
    tax: 'Tax',
    discount: 'Discount',
    pay_now: 'Pay Now',
    date_issued: 'Date Issued',
    visit_us: 'Visit Us',
    contact_us: 'Contact Us',
    email_us: 'Email Us',
    call_us: 'Call Us',
    price: 'Price',
    invoice_to: 'Invoice To',
    receipt_to: 'Receipt To',
    seller: 'Seller',
    thanks_buy: 'Thanks for partnering with us.',
    bill_to: 'Bill To',
    due_date: 'Due Date',
    order: 'Order',
    orders: 'Orders',
    order_by: 'Order by',
    cancel_order: 'Cancel Order?',
    refund: 'Refund if payment was made',
    inactivate_video: 'Inactivate Video',
    confirm_cancel: 'Yes, Cancel Order!',
    from_date: 'From Date',
    to_date: 'To Date',
    description: 'Description',
    monthly_report: 'Monthly Report',
    monthly_orders: 'Monthly Orders',
    hours: 'Hours',
  },
  fields: {
    field: 'Field',
    fields: 'Fields',
    pricing: 'Pricing',
    hour: 'Hour',
    hourly_pricing: 'Hourly Pricing',
    h_pricing: 'H. Price',
    pricing_type: 'Pricing Types',
    general: 'General',
    chunk_frecuency: 'Chunk Frecuency',
    name: 'Name',
    schedule: 'Schedule',
    time_open: 'Time Open',
    time_close: 'Time Close',
    set_time: 'Set Time',
    weekday: 'Weekday',
    open: 'Open',
    close: 'Close',
    update_open_time: 'Update Open Time',
    update_close_time: 'Update Close Time',
    clear_hourly: 'Clear Hourly',
    link: 'Link',
    hourly_discount: 'Hourly Discount',
    recording_type: 'Recording Type',
    static_video: 'Static video',
    tracked_video: 'Tracked video',
  },
  facilities: {
    facilities: 'Facilities',
    facility: 'Facility',
    signer_name: 'Signer Name',
    facility_owner: 'Facility Owner',
    facility_representative: 'Facility Representative',
    administrator: 'Administrator',
    add_comission: 'Add Comission?',
    post_Sale: 'Post-Sale Comission',
    tax_percent: 'Tax Percent',
    logo: 'Logo',
    select_avatar: 'Select Avatar',
    short_address: 'Short Address',
    billing_cycle: 'Billing Cycle',
    general: 'General',
    location: 'Location',
    schedule: 'Schedule',
    enter_location: 'Enter your location',
    monthly_min_orders: 'Monthly Min Orders',
    create_group: 'Create group',
    add_to_group: 'Add to group',
    search_facility: 'Search facility',
    recording_weeks: 'Recordings will be deleted after 30 days.',
  },
  billing_cycle_types: {
    immediately: 'Immediately',
    daily: 'Daily',
    monthly: 'Monthly',
    manually: 'Manually',
    weekly: 'Weekly',
  },
  roles: {
    roles: 'Roles',
    role: 'Role',
    admin: 'Admin',
    facility: 'Facility',
    representative: 'Representative',
    facility_owner: 'Facility Owner',
    facility_representative: 'Facility Representative',
    administrator: 'Administrator',
    name: 'Name',
    description: 'Description',
    general: 'General',
    permissions: 'Permissions',
  },
  cameras: {
    cameras: 'Cameras',
    camera: 'Camera',
    code: 'Code',
    general: 'General',
    thumbnail: 'Thumbnail',
    details: 'Details',
    mac_address: 'Mac Address',
    date: 'Thumbnail Date',
    last_date: 'Last Thumbnail Date',
    description: 'Description',
    name: 'Name',
    refresh_chunk: 'Refresh Chunks',
  },
  videos: {
    videos: 'Videos',
    video: 'Video',
    date: 'Date',
    time: 'Time',
    highlights: 'Highlights',
    highlight: 'Highlight',
    add_order: 'Add Order',
    minutes: 'Minutes',
    add_order_video: 'Add Order Video',
    get_app: ' Get the App Free!',
    dont_have_fanaty: "Don't have Fanaty?",
    using: ' is using Fanaty.',
    app_all: 'Everyone is using Fanaty.',
    video_cost: 'Video cost will be calculated at confirm order',
    views: 'Views',
    show_qr: 'Show QR',
    general: 'General',
    payment: 'Payment',
    first_video_viewed: 'First video viewed',
    first_video_added: 'First video added',
    to_edit: 'to edit',
    start_time: 'Start time',
    make_recurring: 'Make recurring',
    weekdays: 'Weekdays',
    left_video: 'Left Video',
    right_video: 'Right Video',
    left_right_video: 'Left/Right Video',
    download_app_fanaty: 'Download Fanaty App',
    continue_download: 'To continue please download the app...',
    download_app: 'Download App',
    open_app: 'Open App',
    already_have: 'Already have it?',
    need_support: 'Need support?',
    watch_recording: 'Watch Recording',
    dont_have: "Don't have Fanaty yet?",
    no_recordings: 'No recordings found.',
    no_recordings_detail: 'Try diffferent filters, or if your recording is missing please',
    accept_invitation: 'Accept Invitation',
    invited_you: 'invited you to join the group.',
    invitation_expired: 'Invitation Expired',
    invalid_invitation: 'Invalid Invitation',
    ask_group: 'Ask group organizer for another invitation',
    sample_video: 'Sample video',
    dont_have_fanaty_yet: 'Don\'t have Fanaty yet?',
  },
  video_details: {
    duration: 'Duration',
    time: 'Time',
    date: 'Date',
    facility: 'Facility',
    field: 'Field',
    sport: 'Sport',
    amount: 'Video price',
    discount: 'Pre-Order discount',
    final_amount: 'Client final amount',
    comission: 'Comission',
    tax: 'Sales tax',
    group: 'Group',
    start_time: 'Start time',
    total_time: 'Total Time',
    refunds: 'Refunds',
  },
  highlights: {
    highlights: 'Highlights',
    highlight: 'Highlight',
    created_date: 'Created Date',
    created_time: 'Created Time',
    highlight_name: 'Highlight Name ',
    created_by: 'Created By',
    duration: 'Duration',
    category: 'Category',
    type: 'Highlight type',
    goal: 'Goal',
    foul: 'Foul',
    assist: 'Assist',
  },
  info: {
    visit_us: 'Visit Us:',
    contact_us: 'Contact Us:',
    update_available: 'Update available',
    update_available_message: 'A new version of Fanaty is available!',
    update_available_link: 'Please click here to update to the latest version!',
  },
  payment_methods: {
    payment_methods: 'Payment Methods',
    payment_method: 'Payment Method',
    add_card: 'Add card',
    card_exp: 'Card expires at',
    edit_address: 'Edit Address',
    billing_address: 'Billing Address',
    company_name: 'Company Name',
    billing_email: 'Billing Email',
    contact: 'Contact',
    zip_code: 'Zip Code',
    edit_address_info: 'Edit card for future billing',
    new_card: 'New Card',
    new_address_info: 'New card for future billing',
    card_number: 'Card Number',
    card_date: 'MM/YY',
    name_card: 'Name On Card',
    expiration_date: 'Expiration Date',
    cvv: 'CVV',
    cvc: 'CVC',
    default_card: 'Default Card?',
    state: 'State',
    edit_billing_address: 'Edit Billing Address',
    edit_Card: 'Edit Card',
    payment_date: 'Payment Date:',
    payment: 'Payment',
    brand: 'Brand',
    price: 'Price',
    payment_info: 'Payment Info',
  },
  error_404: {
    page_not_found: 'Page Not Found',
    error_message: "we couldn't find the page you are looking for",
    back_home: 'Back to home',
  },
  field_app: {
    open_app: 'Open in Fanaty App',
    dont_have: "Don't have the app?",
    download: 'Download it now!',
  },
  video_app: {
    watch_video: 'Watch Video',
    reproduced: 'Videos can only be reproduced in the mobile app.',
    video_not_found: 'The video does not exist!',
    content_not_exist: "The content doesnt's exist anymore!",
    content_delete: 'Content deleted',
  },
  login: {
    welcome: 'Welcome to',
    message: 'Please sign-in to your account and start the adventure',
    username: 'Username',
    password: 'Password',
    forgot_password: 'I forgot my password',
    login: 'Login',
    enter_phone_email: 'Enter Phone or Email',
    enter_phone_email_detail: 'Enter your phone number or email to access your account',
    or_email: 'Or login with username/email',
    or_phone: 'Or login with phone number',
    or_code: 'Or login with code',
    enter_detail: 'By proceeding, you consent to get electronic mails, calls, WhatsApp or SMS messages, including by automated dialer, from Fanaty and its affiliates to the number or email provided. Text "STOP" to opt out.',
    powered_by: 'Powered by',
    log_in: 'Log In',
    log_in_detail: 'Access your account with your phone or email address and password',
  },
  confirmation_code: {
    confirmation_code: 'Confirmation Code',
    enter_code: 'Enter the confirmation code we sent to',
    didnt_receive: "Didn't get the code?",
    or_password: 'Or login with password',
    or_code: 'Or login with Confirmation code',
  },
  access_code: {
    access_code: 'Access Code',
    enter_code: 'Enter the access code we sent to',
    didnt_receive: "Didn't get the code?",
    or_password: 'Or login with password',
    or_code: 'Or login with access code',
  },
  create_account: {
    create_account: 'Create Account',
    enter_info: 'Enter your profile information',
    first_name: 'First name',
    last_name: 'Last name',
    phone_number: 'Phone number',
    email_address: 'Email address',
    password: 'Password',
    confirm_password: 'Confirm New Password',
    accept_terms: 'I accept the',
    terms: 'terms and conditions',
  },
  is_this_you: {
    is_this_you: 'Is this you?',
    found_account: 'We found an existing account linked to the phone number you entered.',
    yes: "Yes, it's me.",
    no: 'No, this is not me.',
  },
  video_cancelled: {
    video_cancelled: 'Video Cancelled',
    game_cancelled: 'Game Cancelled',
    game_info: 'This game has been cancelled. Search games to see if a new one was created.',
    search_games: 'Search Games',
    search_videos: 'Search Videos',
    download_app: 'Download the app',
    not_available: 'Video Not Available',
    not_valid: 'Vídeo not valid',
    click_search_videos: 'Click on search videos to find others.',
  },
  video_quality: {
    video_quality: 'Video Quality',
    video_quality_detail: 'Choose how you want to have the games recorded.',
    premium: 'Premium',
    basic: 'Basic',
    premium_video: 'Camera follows the action automatically.',
    free_video: 'Free video with no movement.',
  },
  pay_video: {
    pay_video: 'Pay Premium Video',
    pay_video_detail: 'Camera follows the action automatically.',
    card_information: 'Card information',
    save_credit_card: 'Save credit card for future payments.',
    pay: 'Pay',
    and_join: 'and Join',
    without_premium_video: 'Join without premium video',
    credit_card_new: 'New Credit card',
    credit_card: 'Credit card',
    apple_pay: 'Apple pay',
    new_card: 'New Card',
    saved_card: 'Saved Card',
    ends: 'Ends',
    exp: 'Exp',
  },
  player_actions: {
    leave: 'Leave',
    add_guest: 'Add Guest',
    mark_as_paid: 'Mark as Paid',
    remove: 'Remove',
    paid: 'Paid',
    organizer: 'Organizer',
    change_quantity: 'Change Quantity',
    players_quantity: 'Players Quantity',
  },
  add_guest: {
    guest: 'Guest',
    add_guest: 'Add Guest',
    guests_name: "Guest's name",
    guest_info: 'Guests are players without an account. Add their phone or email, and a link of the game will be sent.',
    optional: 'Optional',
    enter_phone_email: 'Enter phone or email',
  },
  landing: {
    share_game: 'Share Game',
    rate_players: 'Rate Players',
    full_video: 'Full Video',
    match_name: 'Game Name',
    match_description: 'Match Description',
    report_issue: 'Report Issue',
    describe_your_issue: 'Describe your issue...',
    join_game: 'Join Game',
  },
  deek_game: {
    join_for: 'Join for',
  },
  settings: {
    logout: 'Logout',
    change_password: 'Change Password',
    report: 'Report',
  },
  table: {
    item_page: 'Rows per page:',
    sort_by: 'Sort By',
    no_data: 'No data available',
    loading_text: 'Loading data...',
  },
  miscellaneous: {
    you_are_not_authorized: 'You are not authorized',
    you_dont_have_permission: 'You don’t have permission to access this page. Go Home',
    back_to_home: 'Back to home',
    page_not_found: 'Page Not Found',
    we_couldnt_find_the_page: "we couldn't find the page you are looking for",
    no_video_on_web: 'Download the mobile app to watch',
  },
  notifications: {
    notifications: 'Notifications',
    notification: 'Notification',
    title_spa: 'Title SPA',
    title_eng: 'Title ENG',
    description_spa: 'Description SPA',
    description_eng: 'Description ENG',
    button_label_spa: 'Button label SPA',
    button_label_eng: 'Button label ENG',
    image: 'Image',
    background: 'Background',
    handled_action: 'Handled action',
    show_modal: 'Show Modal',
    select_background: 'Select a background',
    select_image: 'Select a image',
    only_fanaty_user: 'Only Fanaty user?',
    title: 'Title',
    text: 'Text',
    text_html: 'Text HTML',
    border: 'Border',
    color: 'Color',
    dismissible: 'Dismissible',
    outlined: 'Outlined',
    colored_text: 'Colored Text',
    type: 'Type',
    icon: 'Icon',
    all_groups: 'All Groups',
    all_roles: 'All Roles',
    send_by: 'Sent by',
    created_on: 'Created on',
  },
  groups: {
    groups: 'Groups',
    group: 'Group',
    min_orders_amount: 'Min. order amount',
    min_orders_filter: 'Min. order filter',
    all_groups: 'All groups',
    monthly_charge_amount: 'Monthly Suscription Amount',
    order_reminder_minutes: 'SMS Reminder Minutes',
    notes: 'Notes',
    price_plan: 'Price plan',
    price_range: 'Price range',
    hour_price_range: 'Hour Price Range',
    from: 'From',
    to: 'to',
    up: 'and up',
    hours: 'hours',
    hour: 'hour',
    add_range: 'Add range',
    range_close: 'You must complete the range price!',
    fixed_minimum_amount: 'Fixed minimum amount',
    price_hour_range: 'Price per hour range',
    only_hourly_price: 'Only hourly Price',
  },
  statistics: {
    this_month: 'This Month',
    overview: 'Weekly Orders Number',
    orders: 'Orders amount',
    order_number: 'Number of orders',
    app_users_number: 'Active App Users',
    app_users_number_tooltip: 'Quantity of unique users that interacted with the videos of the facility during the specified period of time. Opening the app does not count as an interaction and the same user might count in multiple facilities',
    comissions: 'Comissions',
    active_facility: 'Active Facilities',
    active_field: 'Active Fields',
    active_camera: 'Active Cameras',
    active_app_users: 'Active App Users',
    active_app_users_tooltip: 'Quantity of unique users that interacted with the app during the specified period of time. Opening the app does not count as an interaction',
    app_sessions: 'App Users Bounce Rate',
    app_sessions_tooltip: 'Percentage of users that open the mobile app but does not interact with any video',
    number_videos: 'Played Videos',
    number_videos_tooltip: 'Played Videos',
    unique_views: 'Unique views',
    unique_views_tooltip: 'Quantity of unique views on all videos. Users are count once per video, but might be counted in multiple videos.',
    avg_per_video: 'Avg per video',
    avg_per_video_tooltip: 'Average Video Views Played',
    sales_by_facility: 'Sales by facility',
    actions_app_by_user: 'Actions App by User',
    actions_app_by_user_tooltip: 'Quantity of interactions per user. Opening the mobile app does not count as an interaction',
    registered_app_users: 'Registered App Users',
    registered_app_users_by_facility: 'Registered App Users by Facility',
    unique_views_by_facility: 'Unique Views by Facility',
    refunds_amount: 'Refunds Amount',
  },
  alert_types: {
    success: 'Success',
    error: 'Error',
    warning: 'Warning',
    info: 'Info',
  },
  border_types: {
    left: 'Left',
    right: 'Right',
    top: 'Top',
    bottom: 'Bottom',
  },
  colors: {
    red: 'Red',
    orange: 'Orange',
    yellow: 'Yellow',
    green: 'Green',
    blue: 'Blue',
    purple: 'Purple',
  },
  date_range: {
    this_year: 'This year',
    this_month: 'This month',
    last_month: 'Last month',
    this_week: 'This week',
    last_week: 'Last week',
    custom: 'Custom',
  },
  notify_players: {
    notify_players: 'Notify Players',
    send_sms: 'Send SMS',
    phone: 'Player phone number',
    add_phone: 'Add phone',
    message: 'A video link will be sent via SMS to each player added.',
    skip: 'Skip',
    requiredPhone: 'You must add at least one phone number',
    you_must_add: 'You must add a phone number if you make order recurring.',
    phone_numbers: 'Phone numbers',
  },
  dashboard: {
    shared_highlights: 'Shared Highlights',
    numbe_of_highlights: 'Number of highlights links shared',
    created_highlights: 'Created Highlights',
    numbe_of_highlights_created: 'Number of highlights created',
    revenue: 'Revenue',
    orders_revenue: 'Orders revenue for a time period',
  },
  reservations: {
    reservations: 'Reservations',
    reservation: 'Reservation',
    new_reservation: 'New Reservation',
    reservation_detail: 'Reservation Detail',
    reservation_save: 'Save Reservation',
    cancel_reservation: 'Cancel Reservation',
    recurring_detail: 'Recurring Detail',
    booking_details: 'Booking details',
    recurring: 'Recurring',
    one_day: 'One Day',
    pick_up: 'Pick Ups ',
    pick_up_match: 'Pickup Match',
    regular_match: 'Regular Match',
    group_match: 'Group Match',
    regular: 'Regular',
    other: 'Other',
    academy: 'Academy',
    academies: 'Academies',
    match: 'Match',
    league: 'League',
    birthday: 'Birthday',
    tournament: 'Tournament',
    insights: 'Insights',
    person: 'Person',
    include_video: 'Include Video',
    player: 'Player',
    per_player: 'Per Player',
    all_members: 'All Group Members',
    confirmed_players: 'Confirmed Players',
    add_group_players: 'Add Group Players',
    add_other_players: 'Add Other Players',
    balance: 'Balance',
    mark_all_paid: 'Mark all paid',
    no_show: 'No Show',
    other_reason: 'Other Reason',
    reason: 'Reason',
    incomplete_reservations: 'Incomplete Reservations',
    repeat_n_weeks: 'Repeat every N weeks',
    frequency: 'Frecuencia',
    incomplete_reason: 'Incomplete Reason',
    not_created: 'Not created due to conflicts',
    contact_name: 'Contact Name',
    contact: 'Contact',
    contacts: 'Contacts',
    contact_phone_numbers: 'Contact Phone Numbers',
    enter_name_phone: 'Enter name or phone',
    enter_name_phone_email: 'Enter name, phone or email',
    add_new_contact: 'Add new contact',
    add_new_group: 'Add new players group',
    group_match_detail: 'Group Match Detail',
    pick_up_match_detail: 'Pick Up Match Detail',
    regular_match_detail: 'Regular Match Detail',
    recurrency_detail: 'Recurrency Detail',
    skill_level: 'Skill Level',
    price_per_player: 'Price per Player',
    min_players: 'Min Players',
    max_players: 'Max Players',
    hours_before_cancel: 'Hours before cancel match (If minimum players not join)',
    reservation_link_copied: 'Reservation link copied!',
    reservation_link_error: 'Error copying reservarion link!',
    free_cancellation: 'Free cancellation before',
    create_highlights: 'Create Highlights',
    are_you_sure: 'Are you sure?',
    this_will_cancel: 'This will cancel the reservation.',
    yes_cancel_reservation: 'Yes, cancel reservation',
    no_kee_reservation: 'No, keep reservation',
    reservation_has_been_cancelled: 'The reservation has been cancelled',
    reservation_was_paid: 'The reservation was paid in full',
    reservation_is_pending: 'The reservation is pending payment.',
    reservation_was_declined: 'The payment was declined, please try again.',
    video_recording_title: 'Video Recording',
    video_recording_text: 'This will be recorded with our artificial intelligent video camera.',
    non_refundable: 'This booking is non-refundable.',
    this_would_not_be_recorded: 'This would not be recorded with our artificial intelligent video camera.',
    pay_for_booking: 'Pay for the booking here before',
    to_activate_video: 'to activate the video recording.',
    reservation_has_been_cancelled_since: 'The recording has been cancelled since payment was not made through here before the start time.',
  },
  pay_dialog: {
    add_payment_method: 'Add payment method',
    credit_card_number: 'CREDIT CARD NUMBER',
    by_pressing_pay: 'By pressing  Pay, you agree to the',
    and: 'and',
    terms_and_conditions: 'terms and conditions',
    privacy_policy: 'privacy policy',
    reservation_will_be_charged: 'The reservation will be charged on',
    reservation_will_be_charged_now: 'The reservation will be charged soon',
    card_ending_in: 'to the card ending in',
    checkout: 'Checkout',
    get_gremium_video: 'Get Premium Video',
  },
  deletion_request: {
    deletion_request: 'Deletion Request',
    deletion_request_text: 'Do you want to delete your account?',
    were_sorry: 'We\'re sorry to see you go. Please note once your account is deleted:',
    no_longer_record: 'We will no longer have any record of your orders or messages with our support team.',
    no_email_sms: 'You will no longer receive notifications emails or SMS.',
    once_account_deletion_process: 'Once the account deletion process is initiated, it cannot be reversed, and you will no longer have access to your account or associated features.',
    delete_account: 'Delete account',
    enter_phone_email: 'Please enter your phone ot email',
    by_deleting: 'By deleting this account you agree to Fanaty Terms & Conditions.',
    received_your_request: 'We have received your request.',
    confirmation_email: 'Our team will send you a confirmation email shortly.',
    are_you_sure: 'Are you sure you want to delete your account?',
  },
}
