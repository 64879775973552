/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { getI18nLanguage } from '@/plugins/i18n'
import { apiHttp } from '../axiosApi'
import {
  landingPayment,
  landingPaymentMethod,
  paymentIntentStripe,
  paymentOrderPremiumVideo,
  paymentIntentUpdateStripe,
  landingPayPremiumVideo,
  userPaymentMethods,
} from '../config/apiRoute'

export const addPayVideo = (body, matchCode) => apiHttp('POST', `${landingPayment}${matchCode}`, body)
export const addPaymentMethod = body => apiHttp('POST', `${landingPaymentMethod}`, body)
export const getPaymentIntentStripe = code => apiHttp('GET', `${paymentIntentStripe}${code}`)
export const putPaymentIntentUpdateStripe = paymentIntentId => apiHttp('PUT', `${paymentIntentUpdateStripe}${paymentIntentId}`)
export const putPaymentOrderPremium = (code, boby) => apiHttp('PUT', `${paymentOrderPremiumVideo}${code}`, boby)

export const addPayPremiumVideo = async info => apiHttp('POST', `${landingPayPremiumVideo}`, info, {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Accept-Language': getI18nLanguage(),
  },
})

export const getUserPaymentMethods = () => apiHttp('GET', `${userPaymentMethods}`)
