import { mdiViewDashboard } from '@mdi/js'

export default [
  {
    id: 20,
    title: 'Dashboard',
    icon: mdiViewDashboard,
    to: 'views-dashboard',
  },
]
