/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate, isAllowed } from '@/plugins/acl/routeProtection'
import { defaultDownloadAppByOS } from '@/@core/utils'
import useCryptoJs from '@/@core/utils/useCryptoJs'
import apps from './apps'
import dashboard from './dashboard'
import pages from './pages'
import userInterface from './user-interface'
import views from './views'

Vue.use(VueRouter)

const { decryptData } = useCryptoJs()

const redirectToBase = () => {
  const data = JSON.parse(localStorage.getItem('userData'))
  const userData = decryptData(data)
  const userRole = userData && userData.user_role ? userData.user_role : null

  // if (userRole === 'admin' || userRole === 'Player' || userRole === 'A' || userRole === 'F' || userRole === 'R') {
  //   if (userData && userData.views_ids && userData.views_ids.length && userData.views_ids.includes(1)) {
  //     return { name: 'views-facility-list' } // dashboard-crm
  //   }

  //   return { name: 'views-facility-list' } // welcome page
  // }

  if (userRole === 'client') return { name: 'views-welcome' }

  return { name: 'views-facility-list' } // 'auth-login'
}

const redirectToNotAuthorized = to => {
  if (to.path.includes('video_detail')) {
    defaultDownloadAppByOS()

    return null
  }

  return { name: 'misc-not-authorized' }
}

const redirectTo404 = to => {
  if (to.path.includes('video_detail')) {
    defaultDownloadAppByOS()

    return redirectToBase()
  }

  return { name: 'error-404' }
}

const routes = [
  {
    path: '/',
    redirect: redirectToBase,
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/login-phone-email',
    name: 'auth-login-phone-email',
    component: () => import('@/views/LoginPhoneEmail.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/access-code',
    name: 'auth-access-code',
    component: () => import('@/views/AccessCode.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/confirmation-code',
    name: 'auth-confirmation-code',
    component: () => import('@/views/ConfirmationCode.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/is-this-you',
    name: 'auth-is-this-you',
    component: () => import('@/views/IsThisYou.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/create-account',
    name: 'auth-create-account',
    component: () => import('@/views/CreateAccount.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/match-detail/:code',
    name: 'match-detail',
    component: () => import('@/views/match-detail/MatchDetail.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      allow: true,
    },
  },

  // component: () => import('@/views/landing/Landing.vue'),
  {
    path: '/g/:code',
    name: 'public',
    component: () => import('@/views/game/Game.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/game/:code',
    name: 'match',
    component: () => import('@/views/game/Game.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
      allow: true,
    },
  },

  {
    path: '/views/public-facily-list',
    name: 'views-public-facility-list',
    component: () => import('@/views/facilities/Facilities.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/views/private-facily-list',
    name: 'views-private-facility-list',
    component: () => import('@/views/facilities/Facilities.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
      allow: true,
    },
  },

  {
    path: '/r/:hash',
    name: 'recording',
    component: () => import('@/views/watch-recording/WatchRecording.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      allow: true,
    },
  },
  {
    path: '/rv/:hash',
    name: 'reservation',
    component: () => import('@/views/reservation/Reservation.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      allow: true,
    },
  },

  {
    path: '/video-cancelled',
    name: 'video-cancelled',
    component: () => import('@/views/VideoCancelled.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/video-not-available',
    name: 'video-not-available',
    component: () => import('@/views/VideoNotAvailable.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
      allow: true,
    },
  },
  {
    path: '/reservation-not-found',
    name: 'reservation-not-found',
    component: () => import('@/views/ReservationNotFound.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
      allow: true,
    },
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/Test.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      allow: true,
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/Register.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/i/:code',
    name: 'app-invitation',
    component: () => import('@/views/AppInvitation.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/app-redirect/:playback',
    name: 'app-redirect',
    component: () => import('@/views/AppRedirect.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/page-not-found',
    name: 'page-not-found',
    component: () => import('@/views/VideoNotFound.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  ...dashboard,
  ...userInterface,
  ...apps,
  ...pages,
  ...views,
  {
    path: '*',
    redirect: redirectTo404,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) => {
  const data = JSON.parse(localStorage.getItem('userData'))
  const userData = decryptData(data)
  const isLoggedIn = userData && userData.access_token && localStorage.getItem('userAbility')

  const allow = !isAllowed(to, userData ? userData.views_ids : [])
  const canNav = !canNavigate(to)

  if (canNav || allow) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'views-facility-list' })

    // If logged in => not authorized
    return redirectToNotAuthorized()
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    if (to.params.code) return next(`/game/${to.params.code}`)

    next('/')
  }

  return next()
})

export default router
