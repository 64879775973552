/* eslint-disable import/no-cycle */
import { getI18nLanguage } from '@/plugins/i18n'
import { apiHttp } from '../axiosApi'
import {
  videoPath,
  videoStartTime,
} from '../config/apiRoute'

export const getVideos = () => apiHttp('GET', `${videoPath}`)

export const getVideoStartTime = async (fieldId, videoDate) => apiHttp('GET', `${videoStartTime}${fieldId}/${videoDate}`, {}, {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Accept-Language': getI18nLanguage(),
  },
})
