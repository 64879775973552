/* eslint-disable no-confusing-arrow */
/* eslint-disable new-cap */
import { computed } from '@vue/composition-api'

export default function useCryptoJs() {
  const AES_ECB_KEY = process.env.VUE_APP_AES_ECB_KEY
  const AES_CTR_KEY = process.env.VUE_APP_AES_CTR_KEY

  const CryptoJS = require('crypto-js/core')
  const AES = require('crypto-js/aes')
  const ECB = require('crypto-js/mode-ecb')

  const userLogged = computed(() => JSON.parse(localStorage.getItem('userData')))
  const encrypted = computed(() => userLogged.value ? userLogged.value : null) // python is base64 ECB
  const key = CryptoJS.enc.Utf8.parse(AES_ECB_KEY) // key used in Python

  const encrypt = code => {
    const Aesjs = require('aes-js')
    const AesCtrConst = Aesjs.ModeOfOperation.ctr
    const AesCtr = new AesCtrConst(JSON.parse(AES_CTR_KEY), new Aesjs.Counter(7))
    const textBytes = Aesjs.utils.utf8.toBytes(JSON.stringify(code))
    const encryptedBytes = AesCtr.encrypt(textBytes)
    const encryptedHex = Aesjs.utils.hex.fromBytes(encryptedBytes)

    return encryptedHex
  }

  const decrypt = code => {
    const Aesjs = require('aes-js')
    const AesCtrConst = Aesjs.ModeOfOperation.ctr
    const AesCtr = new AesCtrConst(JSON.parse(AES_CTR_KEY), new Aesjs.Counter(7))
    const encryptedBytes = Aesjs.utils.hex.toBytes(code)
    const decryptedBytes = AesCtr.decrypt(encryptedBytes)
    const decryptedText = Aesjs.utils.utf8.fromBytes(decryptedBytes)

    return decryptedText
  }

  const decryptData = privateData => {
    if (privateData) return JSON.parse(AES.decrypt(privateData, key, { mode: ECB }).toString(CryptoJS.enc.Utf8))

    return null
  }

  const decryptUserData = () => {
    if (encrypted.value) return AES.decrypt(encrypted.value, key, { mode: ECB }).toString(CryptoJS.enc.Utf8)

    return null
  }

  const userData = computed(() => encrypted.value ? JSON.parse(decryptUserData()) : null)

  return {
    userData,

    encrypt,
    decrypt,
    decryptData,
  }
}
