<template>
  <div>
    <!-- <v-list-item
      class="vertical-nav-menu-link"
      active-class="bg-gradient-primary"
      @click="openChat()"
    >
      <v-list-item-icon>
        <v-icon
          :color="null"
        >
          {{ icons.mdiChatOutline }}
        </v-icon>
      </v-list-item-icon>

      <v-list-item-title>
        {{ t('chat_support') }}
      </v-list-item-title> -->
    <!-- <v-list-item-action
        v-if="item.badge"
        class="flex-shrink-0"
      >
        <v-badge
          :color="item.badgeColor"
          inline
          :content="item.badge"
        >
        </v-badge>
      </v-list-item-action> -->
    <!-- </v-list-item> -->

    <!-- <v-list-item
      class="vertical-nav-menu-link"
      active-class="bg-gradient-primary"
      @click="copy()"
    >
      <v-list-item-icon>
        <v-icon
          :color="null"
        >
          {{ icons.mdiPhone }}
        </v-icon>
      </v-list-item-icon>

      <v-list-item-title>
        {{ phoneNumberText }}
      </v-list-item-title> -->
    <!-- <v-list-item-action
        v-if="item.badge"
        class="flex-shrink-0"
      >
        <v-badge
          :color="item.badgeColor"
          inline
          :content="item.badge"
        >
        </v-badge>
      </v-list-item-action> -->
    <!-- </v-list-item> -->

    <!-- <v-list-item class="vertical-nav-menu-link mb-16"></v-list-item> -->
  </div>
</template>

<script>
import themeConfig from '@themeConfig'
import { useUtils } from '@core/libs/i18n'
import { ref, getCurrentInstance } from '@vue/composition-api'
import { copyText } from 'vue3-clipboard'
import { success, error } from '@core/utils/toasted'
import { isMobile } from '@core/utils'
import { mdiChatOutline, mdiPhone } from '@mdi/js'

export default {
  setup() {
    const { t } = useUtils()
    const vm = getCurrentInstance().proxy
    const phoneNumber = ref('+18889653262')
    const phoneNumberText = ref('(888) 965-3262')

    const openChat = () => {
      if (!window.fcWidget.isOpen()) {
        window.fcWidget.open()
      } else {
        window.fcWidget.close()
      }
    }

    const copy = () => {
      if (isMobile()) {
        window.open(`tel:${phoneNumber.value}`)
      } else {
        copyText(phoneNumber.value, undefined, e => {
          if (e) {
            error(vm.$t('tooltip.not_copy'))
          } else {
            success(vm.$t('tooltip.copied'))
          }
        })
      }
    }

    return {
      alternateIcon: themeConfig.menu.groupChildIcon,
      phoneNumber,
      phoneNumberText,

      openChat,
      copy,

      // i18n
      t,

      // Icons
      icons: {
        mdiChatOutline,
        mdiPhone,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';
</style>
