/* eslint-disable import/no-cycle */
import { apiHttp } from '../axiosApi'
import {
  landingReservation,
  landingReservationCancel,
  landingReservationHoldCard,
} from '../config/apiRoute'

export const getReservation = code => apiHttp('GET', `${landingReservation}${code}`)
export const putCancelReservation = code => apiHttp('PUT', `${landingReservationCancel}${code}`)
export const putHoldCardReservation = (code, body) => apiHttp('PUT', `${landingReservationHoldCard}${code}`, body)
