/* eslint-disable import/no-cycle */
import { getI18nLanguage } from '@/plugins/i18n'
import { apiHttp } from '../axiosApi'
import {
  fieldAll,
  fieldByFacility,
} from '../config/apiRoute'

export const getFields = () => apiHttp('GET', `${fieldAll}`, null, {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Basic ${btoa('')}`,
    'Accept-Language': getI18nLanguage(),
  },
})
export const getFieldsByFacility = id => apiHttp('GET', `${fieldByFacility}${id}`, null, {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Basic ${btoa(`${id}`)}`,
    'Accept-Language': getI18nLanguage(),
  },
})
