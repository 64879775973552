<template>
  <layout-blank class="position-relative">
    <v-card
      v-if="$store.state.app.showUpdate"
      color="primary"
      dark
      class="card-version"
    >
      <v-card-title class="d-flex align-center pb-2">
        <v-icon
          size="1.2rem"
          color="white"
          class="me-2"
        >
          {{ icons.mdiArrowCollapseDown }}
        </v-icon>
        <span class="white--text text-body-1 font-weight-bold">{{ $t('info.update_available') }}</span>
      </v-card-title>

      <v-card-text class="white--text">
        <span>
          {{ $t('info.update_available_message') }}
        </span>
        <span>
          <a
            class="secondary--text text-mail font-weight-regular"
            href="javascript:void(0)"
            @click="onUpdate"
          >
            {{ $t('info.update_available_link') }}
          </a>
        </span>
      </v-card-text>
    </v-card>
    <slot></slot>
  </layout-blank>
</template>

<script>
import LayoutBlank from '@/@core/layouts/variants/blank/LayoutBlank.vue'
import { mdiArrowCollapseDown } from '@mdi/js'
import store from '@/store'

export default {
  components: {
    LayoutBlank,
  },
  setup() {
    const onUpdate = () => {
      store.dispatch('app/toggleUpdate', false)
    }

    return {
      onUpdate,

      icons: {
        mdiArrowCollapseDown,
      },
    }
  },
}
</script>

<style>
.card-version {
  width: 300px;
  position: fixed !important;
  z-index: 20;
  inset-block-end: 5%;
  inset-inline-end: 30px;
}

.text-mail {
  color: #ff3232;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.01875rem;
  text-decoration: underline;
}
</style>
