/* eslint-disable import/no-cycle */
import { getI18nLanguage } from '@/plugins/i18n'
import { apiHttp } from '../axiosApi'
import {
  matchPublic,
  matchPrivate,
  matchOpenCode,
  matchCode,
  matchGameCode,
  matchInfo,
  landingJoinGame,
  sendReportEmail,
  landingPayVideo,
  circleInvitation,
  landingRecordings,
  landingRecordingsHashid,
  landingVideoOrder,
} from '../config/apiRoute'

export const getMatchPublic = code => apiHttp('GET', `${matchPublic}${code}`, null, {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Basic ${btoa(`${code}`)}`,
    'Accept-Language': getI18nLanguage(),
  },
})
export const getMatchPrivate = code => apiHttp('GET', `${matchPrivate}${code}`)

export const getMatchOpenCode = code => apiHttp('GET', `${matchOpenCode}${code}`, null, {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Basic ${btoa(`${code}`)}`,
    'Accept-Language': getI18nLanguage(),
  },
})
export const getMatchCode = code => apiHttp('GET', `${matchCode}${code}`)

export const editMatchInfo = (code, info) => apiHttp('PUT', `${matchInfo}${code}`, info)
export const joinGame = info => apiHttp('POST', `${landingJoinGame}`, info)
export const payVideo = (code, info) => apiHttp('PUT', `${landingPayVideo}${code}`, { info })

export const reportIssue = (code, text) => apiHttp('GET', `${sendReportEmail}${code}`, null, { params: { text_report: text } })

export const getVideoCircle = async hashid => apiHttp(
  'GET',
  `${circleInvitation}${hashid}`,
  {},
  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': getI18nLanguage(),
    },
  },
)

export const getRecordings = async (facilityId, fieldId, videoDate, startTime, lastVideoId) => apiHttp(
  'GET',
  `${landingRecordings}${facilityId}/${fieldId}/${videoDate}/${startTime}/${lastVideoId}`,
  {},
  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': getI18nLanguage(),
    },
  },
)

export const getRecordingsHashid = async hashid => apiHttp(
  'GET',
  `${landingRecordingsHashid}${hashid}`,
  {},
  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': getI18nLanguage(),
    },
  },
)

export const getRecordingVideoOrder = async (groupId, facilityId, fieldId, videoDate, startTime) => apiHttp(
  'GET',
  `${landingVideoOrder}${groupId}/${facilityId}/${fieldId}/${videoDate}/${startTime}`,
  {},
  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': getI18nLanguage(),
    },
  },
)

export const getMatchGameCode = async code => apiHttp(
  'GET',
  `${matchGameCode}${code}`,
  {},
  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': getI18nLanguage(),
    },
  },
  true,
  'v2',
)
