const views = [
  //* ——— Welcome ——————————————————
  {
    path: '/views/welcome',
    name: 'views-welcome',
    component: () => import('@/views/welcome/Welcome.vue'),
    meta: {
      layout: 'content',
      allow: true,
    },
  },
  {
    path: '/views/video-quality',
    name: 'views-video-quality',
    component: () => import('@/views/video-quality/VideoQuality.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
      allow: true,
    },
  },
  {
    path: '/views/video-checkout',
    name: 'views-video-checkout',
    component: () => import('@/views/video-checkout/VideoCheckout.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
      allow: true,
    },
  },
  {
    path: '/views/pay-video',
    name: 'views-pay-video',
    component: () => import('@/views/pay-video/PayVideo.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
      allow: true,
    },
  },
  {
    path: '/views/pay-finished',
    name: 'views-pay-finished',
    component: () => import('@/views/pay-finished/PayFinished.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      allow: true,
    },
  },
  {
    path: '/views/facility-list',
    name: 'views-facility-list',
    component: () => import('@/views/facilities/Facilities.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      allow: true,
    },
  },
  {
    path: '/views/videos',
    name: 'views-video-list',
    component: () => import('@/views/videos/Videos.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
      allow: true,
    },
  },
  {
    path: '/views/video/:facility/:field/:date/:time',
    name: 'views-video',
    component: () => import('@/views/videos/Videos.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
      allow: true,
    },

    // redirect: to => {
    //   const facilityId = to.params.facility ? parseInt(to.params.facility, 10) : 'all'
    //   const fieldId = to.params.field ? parseInt(to.params.field, 10) : 'all'
    //   const dateStr = to.params.data ? to.params.date : 'all'
    //   const timeStr = to.params.time ? to.params.field : 'all'

    //   return {
    //     name: 'views-video-list',
    //     params: {
    //       facility: facilityId,
    //       field: fieldId,
    //       date: dateStr,
    //       time: timeStr,
    //     },
    //   }
    // },
  },
  {
    path: '/views/watch-recording',
    name: 'views-watch-recording',
    component: () => import('@/views/watch-recording/WatchRecording.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      allow: true,
    },
  },
  {
    path: '/views/deletion-request',
    name: 'views-deletion-request',
    component: () => import('@/views/deletion-request/DeletionRequest.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      allow: true,
    },
  },
]

export default views
