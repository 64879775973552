/* eslint-disable import/no-cycle */
import { apiHttp } from '../axiosApi'
import {
  playerRemove,
  playerPaid,
  playerLeave,
  guestApp,
  guestRemove,
} from '../config/apiRoute'

export const removePlayer = (code, slot) => apiHttp('DELETE', `${playerRemove}${code}/${slot}`)
export const paidPlayer = (code, paid) => apiHttp('PUT', `${playerPaid}${code}`, paid)
export const leavePlayer = code => apiHttp('DELETE', `${playerLeave}${code}`)
export const addGuest = body => apiHttp('POST', `${guestApp}`, body)
export const removeGuest = (code, slot) => apiHttp('DELETE', `${guestRemove}${code}/${slot}`)
